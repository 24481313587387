import qrCode from './qrCode.js';
import encodedUrl from './encodedUrl.js';
import customLabels from './customLabels.ts';
import pending from './pending.js';

import storage from '@/utils/storage';

import getters from './getters.js';
import mutations from './mutations.js';
import actions from './actions.js';

import { FillingProgressPosition, LayoutSpacing, QuestionTagType } from './types.ts';

const defaultState = {
    initialized: false,
    isDragging: false,
    hasDragTooltip: false,
    urlCode: null,
    id: null,
    title: '',
    description: '',
    working_title: '',
    created_at: null,
    creator: '',
    logo_file_url: null,
    logo_source_url: null,
    logo_settings: { hasLogo: true, position: 'top-center-above', size: 'small', onlyOnFirstPage: false },
    primary_color: '#1cc6ba',
    text_color: '#212529',
    background_color: '#ffffff',
    title_font_name: null,
    title_font_weight: null,
    title_font_size: 'text-3xl',
    is_question_ordinal_number_visible: null,
    question_tag_type: QuestionTagType.OPTIONAL,
    is_filling_time_visible: null,
    is_filling_progress_visible: true,
    filling_progress_position: FillingProgressPosition.TOP,
    question_font_name: null,
    question_font_weight: null,
    question_font_size: 'text-xl',
    answer_font_name: null,
    answer_font_weight: null,
    answer_font_size: 'text-lg',
    layout_spacing: LayoutSpacing.SPACIOUS,
    is_browser_tab_title_enabled: false,
    favicon: null,
    favicon_url: null,
    language_id: null,
    textSplitting: 1,
    isAutosaveAccepted: false,
    isTest: false,
    elements: [],
    answers: {},
    prevState: '',
    savedElements: null,
    activeElement: 'intro',
    seed: Math.random(),
    thank_you_title: '',
    thank_you_description: '',
    thank_you_image_url: '',
    thank_you_logo_url: '',
    thank_you_logo: '',
    isFinished: false,
    status: 0,
    disqualificationPages: [],
    savedDisqualificationPages: [],
    disqualificationPageChangedLogos: [],
    disqualificationPageChangedFinishPictures: [],
    accent: 1,
    lowercase: 0,
    autoTranslation: false,
    autoTranslationWithHtml: false,
    selectedCustomLabels: [],
    validationErrors: {},
    uniqueName: '',
    urlParameters: [],
    bgImage: null,
    matrixOptionsFromPrevAnswer: {},
    isMultipleFillingOn: false,
    multiFillRestriction: 'any',
    multiFillingRestrictionType: 'cookie',
    isIpCollectionOn: false,
    isAutosaveOn: false,
    password: null,
    responseLimit: null,
    dataSourceId: null,
    datasetId: null,
    hasOwnPrivacyPolicy: false,
    privacyPolicyTitle: null,
    privacyPolicyUrl: null,
    usedUrlParams: [],
    lastResponseAt: null,
    isFreeGroup: false,
    accessedFeatures: [],
    saveInterval: null,
    isAutoPaginationOn: false,
    hasCustomLabelChanges: false,
    hasActiveEmbed: false,
    isArchived: false,
    isNewSurvey: false,
    fillingTime: 0,
    is_multilang: false,
    translations: [],
    translationEdited: false,
    hasNewEmptyTranslations: false,
    showDeactivateTranslationsPopup: false,
    singleResponseLimitTarget: '',
    overallResponseLimitTarget: '',
    thankYouRedirect: null,
    resumableSurveyFillingGuid: null,
    hasNewDictionary: false,
    hasNewDropdownOptionsStoring: 0,
    facebookPixelId: null,
    GTMId: null,
    googleAnalyticsId: null,
};

export const getDefaultState = () => ({ ...defaultState });

const state = getDefaultState();

if (!storage.getItem('show-dnd-popover')) {
    state.hasDragTooltip = true;
}
storage.setItem('show-dnd-popover', 'false');

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        qrCode,
        customLabels,
        pending,
        encodedUrl,
    },
};
